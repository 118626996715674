<template>
  <div id="messages">
    <div class="no-messages" v-if="dialogs.length < 1 && loaded">
      <img :src="require('@/assets/Lock.png')" />
      <p>{{ $t('lock.messages') }}</p>
    </div>

    <Loading v-if="!loaded" />

    <div
      class="dialog"
      :class="{
        unread:
          dialog.lastMsg.read == false &&
          dialog.lastMsg.msgType == 'CHAT_INCOME',
      }"
      v-for="dialog in dialogs"
      :key="dialog.dialogId"
      @click="$router.push(`/chat/${dialog.propertyId}`)"
    >
      <div
        class="avatar"
        :style="{ backgroundImage: `url(${dialog.propertyAvatar})` }"
      >
        <div class="unread-dot"></div>
      </div>
      <div class="info">
        <div class="name">{{ dialog.propertyName }}</div>
        <div class="text">{{ dialog.lastMsg.msg }}</div>
        <div class="time">{{ getTime(dialog.lastMsg.time) }}</div>
        <div class="gradient"></div>
      </div>
      <div class="clickable"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogs: [],
      loaded: false,
    }
  },
  mounted() {
    this.update()
    this.updateInterval = setInterval(this.update, 15000)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    update() {
      this.$api.get(`user/messages`).then(res => {
        this.dialogs = res.data.data
        this.loaded = true
      })
    },
    getTime(date) {
      let time = new Date(date).normalize(true)
      return [
        time.toLocaleString(this.$t('datetimeFormat'), { month: 'numeric' }),
        time.toLocaleString(this.$t('datetimeFormat'), { month: 'short' }),
        time.toLocaleString(this.$t('datetimeFormat'), {
          hour: 'numeric',
          minute: 'numeric',
        }),
      ].join(' ')
    },
  },
}
</script>

<style lang="scss">
#messages {
  margin: 0 auto;
  max-width: 500px;

  .no-messages {
    color: #7a7a7a;
    font-size: 16px;
    text-align: center;

    img {
      opacity: 0.5;
      width: 100px;
      margin-bottom: 10px;
    }
  }

  .dialog {
    width: 95%;
    height: 130px;
    margin: 0 auto;
    border-bottom: 1px solid #e8eceb;
    position: relative;
    cursor: pointer;

    &.unread {
      .avatar {
        .unread-dot {
          display: block;
        }
      }
    }

    > * {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    $avatarSize: 100px;
    .avatar {
      left: 10px;
      width: $avatarSize;
      height: $avatarSize;
      background-size: cover;
      background-position: center;
      border-radius: 100%;
      position: relative;

      .unread-dot {
        display: none;
        position: absolute;
        width: 24px;
        height: 24px;
        border: solid white 3px;
        border-radius: 100%;
        background-color: rgb(225, 35, 5);
        top: 0;
        right: 0;
      }
    }
    .info {
      left: $avatarSize + 40px;
      font-size: 16px;

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .name {
        font-weight: 500;
        margin-bottom: 15px;
      }

      .gradient {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 90%,
          white
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .clickable {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      transition: 0.2s all;
      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
  }
}
</style>
